import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import educationInclusivePdf from '../downloads/education-inclusive-directions-ecole.pdf';
import { StaticImage } from 'gatsby-plugin-image';

function ArticleTitle({ type, title }) {
  return (
    <div className="text-base max-w-prose mx-auto lg:max-w-none">
      <p className="text-base text-primary-500 font-semibold tracking-wide uppercase">{type}</p>
      <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">{title}</h2>
    </div>
  );
}
function ArticleContent({ children }) {
  return <div className="mt-8 prose prose-primary prose-lg text-gray-500 max-w-prose lg:max-w-none">{children}</div>;
}

const ArticlesPage = () => (
  <Layout>
    <Seo title="Articles" description="Les derniers articles de Anne Marie Sow." />
    <div className="py-12 mt-8 flex flex-col items-center">
      <StaticImage className="h-48 w-48 rounded-full" src="../images/maman.jpg" alt="" />

      <h1 className="mt-4 text-5xl font-extrabold">
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-primary-400 to-primary-900">
          Mes écrits
        </span>
      </h1>
      <div className="mt-12 px-4 sm:px-6 md:px-8">
        <div className="max-w-prose mx-auto space-y-12 lg:max-w-4xl">
          <article>
            <ArticleTitle
              type="Article"
              title="Éducation inclusive et directions d'école au Sénégal : Quel partenariat ?"
            />
            <ArticleContent>
              <p>
                Le présent article a pour objectif de déterminer comment le partenariat pour l’inclusion scolaire est-il
                mené auprès des directeurs d’école du Sénégal ? Ces derniers, du fait de leurs missions, sont au cœur du
                système éducatif alors que le partenariat est un élément essentiel pour l’éducation inclusive. Cette
                recherche, à la fois quantitative et qualitative, a été menée dans l’Inspection de l’Éducation et de la
                Formation de Dakar-Plateau auprès de 40 directeurs d’école et d’acteurs clés. Les résultats ont montré
                la faible présence dans le partenariat des directeurs nationaux du Ministère, des collectivités locales,
                des partenaires techniques et financiers, du corps médical et des syndicats alors que le comité de
                gestion et les parents d’élèves sont plutôt présents
              </p>
              <div>
                <a href={educationInclusivePdf} download>
                  Télécharger le PDF
                </a>
              </div>
            </ArticleContent>
          </article>
          <article>
            <ArticleTitle
              type="Thèse en préparation"
              title="Scolarisation et Qualité de vie des élèves atteints d’une maladie invalidante au Sénégal en milieu scolaire, familial et hospitalier. Le cas de la drépanocytose."
            />
            <ArticleContent>
              <p>
                Les élèves porteurs de maladie invalidante ont une scolarisation perturbée par des séjours à l'école et
                à l'hôpital retentissant sur leur qualité de vie en plus des stigmates liés au handicap. Des malentendus
                pédagogiques génèrent des frustrations et un mal-être chez l'élève. En Afrique 10 à 40 % de la
                population sont porteurs de la drépanocytose (OMS, 2011). Handicap invisible, son recensement n'est pas
                effectif et pose le problème de la connaissance. Notre recherche vise à déterminer, d'une part, comment
                l'équipe pédagogique s'organise-t-elle pour l'inclusion scolaire des élèves porteurs de maladie
                invalidante au Sénégal et, d'autre part, à analyser les outils sociaux, communautaires, étatiques et
                autres dispositifs de leur prise en charge. L'intérêt de cette recherche est qu'elle se propose de
                combler un vide dans la gestion des élèves porteurs de maladie invalidante au Sénégal en faisant
                intervenir 3 milieux clés : l'école, la société et l'hôpital. Il s'agit d'un champ non exploré. Notre
                méthodologie consiste à faire une recherche documentaire et une recherche empirique avec des données
                quantitatives et qualitatives à travers des outils : questionnaire et guide d'entretien. Nous comptons
                utiliser un échantillon représentatif d'élèves drépanocytaires, d'enseignants, de parents, de personnels
                médicaux.
              </p>
              <div>
                <a href="http://www.theses.fr/s346667">Voir sur theses.fr</a>
              </div>
            </ArticleContent>
          </article>
          <article>
            <ArticleTitle
              type="Mémoire"
              title="L'implication des directeurs d'école élémentaire dans l'inclusion scolaire des enfants en
              situation de handicap, 2020 UCAD - FASTEF - CUSE"
            />
            <ArticleContent>
              <p>
                L’éducation inclusive intègre les mesures que l’école doit prendre pour les enfants en situation de
                handicap. Le directeur d’école, à travers son rôle central et ses missions pédagogiques, administratives
                et sociales, joue un rôle primordial dans la politique éducative au sein de son établissement. Cette
                recherche vise à déterminer d’une part, comment les directeurs d’école élémentaire comprennent le
                handicap et d’autre part de décrire leur implication dans l’inclusion scolaire. Elle se fonde sur une
                étude quantitative et qualitative. La recherche quantitative de 183 variables menée auprès de 40
                directeurs d’école en milieu urbain dans la zone de l’Inspection de l’Education et de la Formation de
                Dakar-Plateau avec un modèle d’analyse de l’implication inspiré de celui d’Allen et de Meyer (1993). La
                recherche qualitative a concerné 15 directeurs, 3 parents et 2 associations. Les résultats ont montré
                que les directeurs ont des connaissances limitées sur le handicap. Ils connaissent l’importance, mais
                sont faiblement impliqués dans l’éducation inclusive.
              </p>
              <div>
                <a href="https://drive.google.com/file/d/1ueYGADQzn3c7ZOolDkdNJHjfGDl1WIY9/view?usp=sharing">
                  Voir le mémoire
                </a>
              </div>
            </ArticleContent>
          </article>
          <article>
            <ArticleTitle
              type="Mémoire"
              title="Diabète sucré et comportement alimentaire des sénégalais, 1988 UCAD - ENSETP"
            />
            <ArticleContent>
              <p>
                Le diabète sucré est une maladie chronique qui apparait lorsque le pancréas ne produit plus suffisamment
                d'insuline ou quand le corps ne parvient plus à utiliser efficacement l'insuline qu'il produit.
                L'alimentation joue un rôle important dans l'équilibre du diabète. Le comportement alimentaire est
                l'élément le plus difficile à changer dans un groupe. Cette recherche effectuée en 1988, au centre
                antidiabétique de l'hôpital Abass Ndao de Dakar, analyse le comportement alimentaire des Sénégalais et
                ses conséquences dans le diabète sucré.
              </p>
              <div>
                <a href="https://drive.google.com/file/d/1rY9oqWQSc6Y1H229A_ymrNx-Mlm3QH1E/view?usp=sharing">
                  Voir le mémoire
                </a>
              </div>
            </ArticleContent>
          </article>
          <article>
            <ArticleTitle
              type="Manuel"
              title="Manuel du Peacekeeper pour l’Education à la Paix en milieu communautaire, 2013"
            />
            <ArticleContent>
              <p>
                Le Projet PDC Africa “Femmes et Jeunes d’Afrique Unis pour la Paix, la Démocratie et la Citoyenneté”,
                subventionné par le programme Alumni Engagement Innovation Fund (AEIF), du Département d’État des USA,
                est classé premier à l’issue du vote des alumnis sur 685 projets dans le monde dont 154 finalistes, il
                fait partie des 50 projets sélectionnés dans le monde dont 10 en Afrique. Coordonné par Mme Anne Marie
                SOW, le PDC Africa est managé par une équipe internationale avec plusieurs organisations partenaires et
                couvre l’Afrique de l’ouest et du centre notamment le Sénégal, le Mali, le Niger, la Guinée, le
                Cameroun. Le manuel «Peacekeeper en action» est conçu par une équipe de bénévoles et fait partie des
                activités phares du projet PDC Africa. 50 peacekeepers, c'est-à-dire ambassadeurs de la paix, ont
                bénéficié de la formation des formateurs au Sénégal. Cet ouvrage est destiné à tout animateur
                communautaire de séances sur la paix, la citoyenneté, la démocratie, les droits humains. Toute
                organisation défenseur des droits humains peut en tirer profit. Le premier chapitre du manuel est dédié
                aux techniques d’animation pour adultes. Le deuxième est composé de huit séances, faciles à réaliser
                dans un temps record d’environ 30 minutes par séance. Le troisième chapitre sert d’informations pour la
                documentation du peacekeeper. L’ouvrage est illustré pour mieux répondre au renforcement des adultes en
                tenant compte des réalités socioculturelles des populations africaines qui seront renforcées en jouant,
                en causant. À vous, Peacekeeper, faites bon usage de ce livret Help Memory !
              </p>
              <div className="flex space-x-4">
                <a href="https://drive.google.com/file/d/1wq4nz1xSJCfIZ-mMFXvLuhJEEUmAMVar/view?usp=sharing">
                  Voir en français
                </a>
                <span>/</span>
                <a href="https://drive.google.com/file/d/1v9zyk17ZVg5vyB5KqGFFNAWN2WSuZObD/view?usp=sharing">
                  Voir en anglais
                </a>
              </div>
            </ArticleContent>
          </article>
        </div>
      </div>
    </div>
  </Layout>
);
export default ArticlesPage;
